.input {
	display: flex;
	flex-direction: column;
	gap: 4px;
	width: 100%;
	&__group {
		width: 100%;
		display: flex;
		align-items: center;
		gap: 8px;
		height: 56px;
	}
	&__label {
		color: #747e99;
		font-size: 12px;
		font-weight: 500;
		line-height: 16px;
		font-size: var(--font-size-3);
		line-height: var(--line-height-3);
		color: var(--color-text-70-light);
		text-transform: capitalize;
		font-weight: 500;
		& > span {
			color: red;
			margin-left: 4px;
			font-size: 15px;
		}
	}

	&__text-field {
		width: 100%;
		height: 48px !important;
		display: flex;
		align-items: center;
		background: var(--color-input-background);
		border: 1px solid var(--color-input-border-light);
		border-radius: calc(var(--border-radius) * 0.5);
		padding: calc(var(--padding) * 0.5);
		font-size: var(--font-size-4);
		line-height: var(--line-height-4);
		&:focus-within {
			border: 1px solid var(--color-primary-light);
			transition: 0.2s ease-in all;
		}
		&--error {
			border-color: var(--color-error);
			background-color: hsla(0, 100%, 50%, 0.06);
		}
	}
	&__value-container {
		display: flex;
		flex-direction: column;
		gap: 4px !important;
	}
	&__field {
		text-overflow: ellipsis;
		width: 100%;
		background-color: var(--color-transparent);
		border: none;
		outline: none;
		padding: 0px calc(var(--padding) * 0.5);
		font-size: 14px;
		font-weight: 400;
		&:disabled {
			cursor: not-allowed;
		}
	}
	&__delete {
		padding: 0px calc(var(--padding) * 0.25);
		cursor: pointer;
		font-weight: 600;
		opacity: 0.5;
	}

	&__view {
		padding: 0px calc(var(--padding) * 0.25);
		cursor: pointer;
		font-weight: 600;
		opacity: 0.5;
		font-size: 24px;
		&:hover {
			color: var(--color-black);
			opacity: 0.6;
		}
	}

	&__error {
		color: var(--color-error);
		font-size: var(--font-size-2);
		font-weight: 500;
		position: initial !important;
		text-wrap: wrap;
	}
	.country-label {
		background: transparent;
	}
}

/* this will remove the increment and decrement icon from the number input */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}
