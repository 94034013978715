:root {
	--color-input-border-light: #d7dff5;
	--color-white: #ffffff;
	--color-input-label-light: #6c707a;
	--color-bg-light-90: #f5f8ff;
	--color-border-light-100: #dfe3ec;
	--color-input-text-dark: #1b1c1f;
	--color-btn-primary: #458bf5;
	--color-btn-secondary: rgba(47, 50, 61, 0.06);
	--color-text-light-80: #464c5c;
	--color-text-light-40: #9ea3b3;
	--color-text-light-70: #6c7489;
	--color-text-light-80: #515767;
	--color-text-light-90: #363a45;
	--color-text-light-60: #7c8398;
	--color-hover-light-100: hsla(224, 32%, 36%, 0.06);
	--color-primary: #3c65d6;
	--color-bg-light-90: #f4f5f5;
	// please remove them and use variables properly as adviesd by the designer
	--black: black;
	--white: #ffffff;
	--blue: blue;
	--lightblue: lightblue;
	--red: red;

	--border-gray: gray;
	--theme-gray: #f3f4f7;

	--color-orange: #e88a2c;
	--color-light-orange: #bc936a;
	--color-red: #de3535;
	--color-blue: #0051cc;
}
