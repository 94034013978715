.representative-header {
	width: 100%;
}
.Representative-detail {
	width: 100%;
	height: 100%;
	overflow-y: auto;
	margin-bottom: 40px;
	@media screen and (min-width: 768px) {
		margin-bottom: 80px;
	}
	.representative-note {
		display: flex;
		padding: 12px 16px;
		align-items: center;
		gap: 8px;
		border-radius: 4px;
		border: 1px solid var(--color-orange);
		background: rgba(239, 182, 67, 0.06);
		margin-bottom: 12px;

		&_icon {
			font-size: 16px;
			color: var(--color-orange);
		}

		&_text {
			color: var(--color-text-light-80);
			font-size: 12px;
			font-weight: 500;
			line-height: 16px;
			span {
				color: var(--color-text-light-80);
				font-size: 12px;
				font-weight: 600;
				line-height: 16px;
			}
		}
	}
	.representative-add {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 8px;
		color: var(--color-text-light-90);
		font-size: 14px;
		font-weight: 500;
		grid-column: span 2 / span 2;
		line-height: 24px;

		&__disabled {
			opacity: 0.6;
			cursor: not-allowed;
		}
		&_button {
			padding: 4px 8px;
			text-align: center;
			gap: 8px;
			border-radius: 4px;
			background: rgba(0, 81, 204, 0.06);
			color: var(--color-blue);
			cursor: pointer;
			border: 1px solid transparent;

			&:hover {
				border: 1px solid var(--color-blue);
			}
		}

		&_text {
			color: #000;
			line-height: 20px;
		}

		&_delete {
			display: flex;
			padding: 4px 8px;
			align-items: flex-start;
			gap: 8px;
			border-radius: 4px;
			background: rgba(245, 83, 83, 0.12);
			color: var(--color-red);
			cursor: pointer;
			border: 1px solid transparent;

			&:hover {
				border: 1px solid var(--color-red);
			}
		}
	}
	.representative-add-wrapper {
		display: flex;
		flex-direction: column;
		gap: 16px;
		padding: 12px;
		border-radius: 4px;
		border: 1px dashed rgba(0, 81, 204, 0.24);
		@media screen and (min-width: 786px) {
			display: grid;
			grid-template-columns: 1fr 1fr;
			gap: 20px;
			align-items: flex-start;
		}

		.input {
			height: unset !important;
		}

		input[type='date'] {
			border: none;
			box-sizing: border-box;
			outline: 0;
			padding: 0.75rem;
			position: relative !important;
			width: 100%;
			&::-webkit-calendar-picker-indicator {
				background: transparent;
				bottom: 0;
				color: transparent;
				cursor: pointer;
				height: auto;
				left: 0;
				position: absolute;
				right: 0;
				top: 0;
				width: auto;
			}
		}

		input[type='date']:before {
			content: none !important;
			background: transparent !important;
		}
		& .mobile-input__container .country-list {
			bottom: 100%;
		}
	}
	&--filledWrapper {
		display: flex;
		gap: 8px;
		grid-column: span 2 / span 2;
	}
	&--filledHeader {
		display: flex;
		flex-direction: column;
		gap: 4px;
		width: 100%;
	}
	&--filledTitle {
		font-size: var(--font-size-3);
		font-weight: 600;
		line-height: 20px;
		max-width: 260px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	&--filledDesc {
		font-size: var(--font-size-2);
		font-weight: 400;
		line-height: 16px;
		color: var(--color-text-light-70);
		max-height: 48px;
		overflow-y: hidden;
		word-break: break-word;
	}
}

.representative-wrapper {
	display: flex;
	flex-direction: column;
	gap: 12px;
	position: relative;

	&--representative-btn {
		position: sticky;
		bottom: 0;
		max-width: var(--window-viewport);
		width: 100%;
		background-color: var(-white);
		padding: 10px 10px 0 10px;
	}
}
